"use client";

import { ContentBlockServer } from "@/components/ContentBlock";
import { LogoNoLinkServer, LogoServer } from "@/components/Logo";
import { cva } from "class-variance-authority";
import Link from "next/link";
import { CreditCards } from "@/components/CreditCards";
import type { Payload } from "@local/payload-client/src/types";
import { IS_BAERSKIN, IS_HAM, MINISTORE } from "@/lib/const";
import { RegionSelector } from "@local/i10n/src/RegionSelector";
import { GorgiasLink } from "@/components/Footer";
import { OrganizationSchemaJsonLd } from "@local/utils/src/jsonLdSchemas/Organization";
import { TrustpilotMicroCombo } from "@/components/TruspilotWidgets";
type FooterListItem = Payload.Mini["navigationFooter"][number];
interface FooterProps {
  hiddenSelector?: boolean;
  logo?: Payload.Media | null;
  navigationFooter?: FooterListItem[];
  siteName?: string;
  initialData: Payload.Mini;
}
const fullWidthBgColor = cva("", {
  variants: {
    store: {
      cozistore: "bg-gray-900",
      hamstore: "bg-sky-950",
      baerskintactical: "bg-gray-900"
    }
  },
  defaultVariants: {
    store: "cozistore"
  }
});
const style = cva("flex justify-between px-5 py-8 md:flex-row flex-col-reverse", {
  variants: {
    store: {
      cozistore: "text-white",
      hamstore: "text-white",
      baerskintactical: "text-white"
    }
  },
  defaultVariants: {
    store: "cozistore"
  }
});
const FooterListItem = ({
  id,
  name,
  subitems
}: FooterListItem) => {
  return <div className="mb-6 text-center sm:mb-6 md:text-left" data-sentry-component="FooterListItem" data-sentry-source-file="Footer.tsx">
      <h2 className="mb-2 text-lg font-bold lg:mb-3">{name}</h2>
      <ul>
        {subitems?.map(({
        name,
        url
      }, i) => <li key={`footer-list-subitem-${id}-${i}`} className="whitespace-nowrap">
            {url === "/chat-with-us" ? <GorgiasLink string={name} /> : url.substring(0, 4) === "http" || url.substring(0, 4) === "mail" ? <Link href={url} target="_blank" rel="noreferrer" prefetch={false} aria-label={`Redirect to ${name} in a new tab`}>
                {name}
              </Link> : <Link href={url} prefetch={false}>
                {name}
              </Link>}
          </li>)}
      </ul>
    </div>;
};
export const Footer = ({
  hiddenSelector,
  logo,
  navigationFooter,
  siteName,
  initialData
}: FooterProps) => {
  const store = MINISTORE;
  const storeName = siteName ?? "Baerskin Tactical";
  return <footer id="footer" className="flex-shrink-0 w-full" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <ContentBlockServer fullWidthBgColor={fullWidthBgColor({
      store
    })} className={style({
      store
    })} data-sentry-element="ContentBlockServer" data-sentry-source-file="Footer.tsx">
        <div className="flex flex-col items-center justify-between mt-6 sm:mt-0">
          <div />

          {IS_BAERSKIN || IS_HAM ?
        //this spacing doesn't bother anything in case TP doesn't load.
        <div className="mb-3 md:hidden md:invisible">
              <TrustpilotMicroCombo dark tall cssClass="!mt-2 -mb-1 md:mb-1" />
            </div> : null}

          <div className="w-fit md:-ml-4 ">
            {!navigationFooter ? <LogoNoLinkServer image={logo} storeName={storeName} noHeaderElement /> : <LogoServer image={logo} storeName={storeName} noHeaderElement />}
          </div>

          {IS_BAERSKIN || IS_HAM ? <div className="invisible hidden mb-3 md:block md:visible">
              <TrustpilotMicroCombo dark tall cssClass="!mt-2 -mb-1 md:mb-1" />
            </div> : null}

          <div className="w-full mb-4 text-center md:text-left">
            {!navigationFooter && <Link href="/" prefetch={false}>
                &larr; Back to Homepage
              </Link>}
          </div>
          <div className="w-full my-4 md:flex md:justify-center">
            <div className="m-auto md:m-0 w-fit">
              <RegionSelector hidden={hiddenSelector} id="region-selector-mini-store-footer" data-sentry-element="RegionSelector" data-sentry-source-file="Footer.tsx" />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center mx-auto mt-2 text-sm sm:mx-0 md:items-start">
            <p>
              &copy; 2018-{new Date().getFullYear()} {storeName}. All rights
              reserved.
            </p>
          </div>
        </div>
        <div>
          <div className="flex flex-col items-center px-4 mb-5 text-sm leading-6 md:items-start md:gap-12 md:flex-row md:justify-end">
            {navigationFooter?.map((item, i) => <FooterListItem key={`footer-list-nav-${item.id}-${i}`} {...item} />)}
          </div>
          {navigationFooter && <div className="hidden invisible md:visible md:w-[520px] bg-white rounded-full px-2 py-1 md:block ml-auto">
              <CreditCards fillHeight />
            </div>}
        </div>
      </ContentBlockServer>
      <OrganizationSchemaJsonLd brand={storeName} item={initialData} data-sentry-element="OrganizationSchemaJsonLd" data-sentry-source-file="Footer.tsx" />
    </footer>;
};